import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import AppHome from './AppHome.js';

function App() {
/*
  
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAccount, setWalletAccount] = useState("");
  // RED TOKEN PRUEBA
  //const tokenAddress = "0x3E7d9cdF43c9889CA1578b663ca684C1F1539F64";
  // RED TOKEN
  //const tokenAddress = "0x4313EF7e5A4489A3D13BA9E65717ae7c09Fe64a9"; 
  // RED TOKEN mainnet
  //const tokenAddress = "0xE920a57a4E98C88968f50D6fe724FF0Ef1D58D7F"; //Polygon
  const tokenAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"; //Local Blockchain hardhat
  const stakingAddress = "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512"; //Local Blockchain hardhat
  
  const checkConnection = async() => {
    await window.ethereum
        .request({ method: 'eth_accounts' })
        .then(handleAccountsChanged)
        .catch(console.error);
  };

  
  const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
          setWalletConnected(false);
          setWalletAccount("");
      } else {
          setWalletConnected(true);
          setWalletAccount(accounts[0]);
      }
  };


  useEffect (() => {    
      checkConnection();
  });


  return (
    <div className="App">
      
      { window.ethereum ?
        (!walletConnected ? 
          <AppLogin /> : 
          <AppAuth2 walletAccount={walletAccount} tokenAddress={tokenAddress} stakingAddress={stakingAddress}/>) :
        "Metamask or other EIP-1102 / EIP-1193 compliant wallet not found."
      }
      
    </div>
*/
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppHome />} />
        <Route
            path="*"
            element={<Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
