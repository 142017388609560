import React,{ useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {CssBaseline} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import "@fontsource/poppins";
import HeaderStatic from './Fragments/HeaderStatic';
import Configuracion from './Auth/Configuracion';
import Dashboard from './Auth/Dashboard';


const ERC20Token = require("../components/ERC20/ERC20Token");
const { web3, applyDecimals } = require("../utils/ethereumAPI");

const AppAuth2 = ({walletAccount, tokenAddress, stakingAddress,stakingAddress18,stakingAddress24}) => {
  const [view, setView] = useState(0);
  const [title, setTitle] = useState("My Wallet Dashboard");
  const ref = React.useRef(null);

  const [tokenData, setTokenData] = useState([
    { id: 0, name: 'TokenAddress', value: tokenAddress},
    { id: 1, name: 'Name', value: ''},
    { id: 2, name: 'Symbol', value: ''},
    { id: 3, name: 'TotalSupply', value: ''},
    { id: 4, name: 'Decimals', value: ''},
    { id: 5, name: 'Currentbalance', value: ''}
  ]);

  //const [accountAddress, setAccountAddress] = useState("");

  useEffect(() => {
      async function fetchData(){
          const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
          const name = await web3Token.methods.name().call();
          const symbol = await web3Token.methods.symbol().call();
          const totalSupply = await web3Token.methods.totalSupply().call();
          const decimals = await web3Token.methods.decimals().call();

          const accounts = await web3.eth.getAccounts();
          //setAccountAddress(accounts[0]);
          const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();

          setTokenData(tokenData => [
              tokenData[0],
              { ...tokenData[1], value: name},
              { ...tokenData[2], value: symbol},
              { ...tokenData[3], value: applyDecimals(totalSupply, decimals)},
              { ...tokenData[4], value: decimals},
              { ...tokenData[5], value: applyDecimals(currentBalance, decimals)},
          ])
      }
      fetchData();
  }, [tokenAddress]);

  return (
    <Container component="main" maxWidth="lg" sx={{ height:`calc(100%}px)`}} >
      <CssBaseline />
      <HeaderStatic title={title}/>
      <Grid container >
          <Grid item xs={12} >
            <Box sx={{ display: 'flex',flexDirection: 'column',alignItems: 'center',marginTop: 4,paddingTop: 0}} >
              
            </Box>
            {/* view === 0 ?
              <MyWallet walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/>: 
              view === 1 ? <RecibirToken walletAccount={accountAddress} tokenData={tokenData} tokenAddress={tokenAddress}/>: 
              view === 2 ? <EnviarToken walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/> :
              <Inversion walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/> 
            */}
            { view === 0 ?
              <Dashboard walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/> :
              <Configuracion walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/>
            }
          </Grid>
      </Grid>
      <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={view}
            onChange={(event, newValue) => {
              setView(newValue);
            }}
          >
            <BottomNavigationAction label="Dashboard" icon={
              /* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */
              <span style={{display: 'block', width: '1.25em', height: '1em', fontSize: 16, marginBottom: 6 }}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentColor'}} viewBox="0 0 512 512"><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/></svg>
              </span>
            } onClick={(e) => setTitle("My Wallet")} />
            <BottomNavigationAction label="Settings" icon={
              /* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */
              <span style={{display: 'block', width: '1.25em', height: '1em', fontSize: 16, marginBottom: 6 }}>                
                <svg xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentColor'}} viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z"/></svg>
              </span>
            } onClick={(e) => setTitle("Configuración")}/>
            
          </BottomNavigation>
        </Paper>
      </Box>
    </Container>
  );


}


export default AppAuth2
