import React,{ useState } from 'react';
import { Typography, Card, Box, Grid } from '@mui/material';
import red_logo_2 from '../../assets/img/logos/LOGO-RED-MONEY.png';

import { ethers } from 'ethers'

import Staking from '../../artifacts/contracts/Staking.sol/Staking.json';
import Staking18 from '../../artifacts/contracts/Staking18.sol/Staking18.json'; 
import Staking24 from '../../artifacts/contracts/Staking24.sol/Staking24.json';

const { paridad } = require("../../utils/ethereumAPI");

const CardInfo = ({walletAccount, tokenData, stakingAddress, stakingAddress18, stakingAddress24}) => {

  const [balance, setBalanceValue] = useState(0)
  const [balance18, setBalanceValue18] = useState(0)
  const [balance24, setBalanceValue24] = useState(0)

  const currentBalance = tokenData.find(x => x.name === "Currentbalance").value;
  const symbolToken = tokenData.find(x => x.name === "Symbol").value;
  const moneyFormat = (value, money) => {
    let options = money ? { style: 'currency', currency: 'USD'} : {minimumFractionDigits: 2};
    return new Intl.NumberFormat('en-US', options).format(value);
  }


  async function getBalance(){
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum)

      
      let contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      let contract18 = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      let contract24 = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      

      try {
        const data = await contract.connect(walletAccount).getBalance();
        let balance = data / 1e18
        setBalanceValue(balance);

        const data18 = await contract18.connect(walletAccount).getBalance();
        let balance18 = data18 / 1e18
        setBalanceValue18(balance18);

        const data24 = await contract24.connect(walletAccount).getBalance();
        let balance24 = data24 / 1e18
        setBalanceValue24(balance24);
        //console.log('data balance: ', balance)
      } catch (err) {
        console.log("Error: ", err)
      }
    } 
  }

  getBalance();

  return (
    <div>
        <Grid container spacing={2} sx={{
            mb: 2, 
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Grid item>
                <img src={red_logo_2} 
                style={{maxWidth:'60px', display: 'block'}}
                alt="Red money"/> 
            </Grid>
            <Grid item>
                <Typography variant="h6" component="div" sx={{
                    fontWeight: 700,
                    lineHeight: 1,
                    fontSize: 24
                }}>
                    RED MONEY
                </Typography>
            </Grid>
        </Grid>
        <Card sx={{
            background: 'linear-gradient(45deg, #c91515, #e72929)',
            px: 4,
            py: 3,
            textAlign: 'left',
            boxShadow: '2px 2px 10px #00000040'
        }}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography sx={{
                        fontSize: 14,
                        color: '#fff',
                        fontWeight:600,
                        mb: 1

                    }}>
                        Total Wallet Balance
                    </Typography>
                    <Typography variant="h6" component="div" sx={{
                        color: '#fff',
                        fontWeight: 700,
                        fontSize: 26,
                        lineHeight: 1.4,

                    }}>
                        {moneyFormat(currentBalance)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{
                        fontSize: 14,
                        color: '#fff',
                        fontWeight:600,
                        mb: 1

                    }}>
                        <b>Staking Summary:</b><br/><br/>
                        Staking 12 meses: {moneyFormat(balance)} RED<br/>
                        Staking 18 meses: {moneyFormat(balance18)} RED<br/>
                        Staking 24 meses: {moneyFormat(balance24)} RED<br/>
                        
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{
                        color: '#fff',
                        fontWeight: 700,
                        fontSize: 26,
                        lineHeight: 1.4,

                    }}>
                        <Box sx={{                        
                            fontSize: '0.6em',
                            lineHeight: 1,
                            fontWeight: 400,
                        }}>
                            {symbolToken}
                        </Box>
                    </Typography>
                    <br/>
                    <Typography sx={{ 
                                fontSize: 12,
                                fontWeight: 800,
                                background: '#fff',
                                color: '#aa0000',
                                lineHeight: 1,
                                py: 0.3,
                                px: 1,
                                mr:5,
                                borderRadius: 4

                            }}>
                                {"Disponible: "+ moneyFormat(currentBalance * .1)} USDT
                            </Typography>
                </Grid>
                <Grid item xs={6}
                    
                >
                    <Grid item xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}>
                        <Typography sx={{
                            fontSize: 14,
                            color: '#fff',
                            fontWeight:300,
                            mb: 1,
                            py: 0.3,
                            px: 1,

                        }}>
                            <b>Total: {moneyFormat(balance + balance18 + balance24)} RED</b><br/>    
                        </Typography>   
                    <br/>
                    </Grid>
                    <Grid item xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                    }}>
                    <br/>
                            <Typography sx={{ 
                                fontSize: 12,
                                fontWeight: 800,
                                background: '#fff',
                                color: '#aa0000',
                                lineHeight: 1,
                                py: 0.3,
                                px: 1,
                                borderRadius: 4

                            }}>
                                Staking: {moneyFormat((balance * .1) + (balance18 * .1) + (balance24 * .1), true)} USDT
                            </Typography>

                    </Grid>
                 
                    
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ 
                        fontSize: 12,
                        fontWeight: 800,
                        background: '#fff',
                        color: '#aa0000',
                        lineHeight: 1,
                        py: 0.3,
                        mt: 4,
                        px: 1,
                        borderRadius: 4,
                        textAlign: "center"

                    }}>
                        Total: {moneyFormat((currentBalance * paridad) + (balance * .1) + (balance18 * .1) + (balance24 * .1), true)} USDT
                    </Typography>     
                </Grid>
            </Grid>
        </Card>        
    </div>
  )
}

export default CardInfo