import React,{ useState, useEffect } from 'react';
import CardInfo from './CardInfo';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ethers } from 'ethers'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Staking from '../../artifacts/contracts/Staking.sol/Staking.json';
import Staking18 from '../../artifacts/contracts/Staking18.sol/Staking18.json'; 
import Staking24 from '../../artifacts/contracts/Staking24.sol/Staking24.json';
import RedMoney from '../../artifacts/contracts/RedMoney.sol/RedMoney.json';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { CSVLink } from "react-csv"

//import Highcharts, { dateFormat } from 'highcharts'
//import HighchartsReact from 'highcharts-react-official'

//import Grafica from "../../Grafica.js";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ERC20Token = require("../ERC20/ERC20Token");
const { web3, applyDecimals } = require("../../utils/ethereumAPI");
const moneyFormat = (value, money) => {
  let options = money ? { style: 'currency', currency: 'USD'} : {minimumFractionDigits: 2};
  return new Intl.NumberFormat('en-US', options).format(value);
}

/* TAB FUNCTIONS */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


/* END TAB FUNCTIONS */



function createData(id, amount, end, active, actions) {
  return {id, amount, end, active, actions};
}

function createLogData(id, Address, Amount, Date, Event) {
  return {id, Address, Amount, Date, Event};
}

const Dashboard = ({walletAccount, tokenData, tokenAddress, stakingAddress, stakingAddress18, stakingAddress24}) => {

  const [staking, setStakingValue] = useState(0)
  const [allowance, setAllowanceValue] = useState(0);
  const [reward, setRewardValue] = useState(0)
  const [balance, setBalanceValue] = useState(0)
  const [totalStakedContract, setTotalStakedContractValue] = useState(0)
  const [totalStakedContract12, setTotalStakedContractValue12] = useState(0)
  const [totalStakedContract18, setTotalStakedContractValue18] = useState(0)
  const [totalStakedContract24, setTotalStakedContractValue24] = useState(0)

  // Fondeo
  
  const [fondeo12, setFondeo12] = useState(0)
  const [fondeo18, setFondeo18] = useState(0)
  const [fondeo24, setFondeo24] = useState(0)

  //Total de red pagado
  const [totalRewardContract12, setTotalRewardContractValue12] = useState(0)
  const [totalRewardContract18, setTotalRewardContractValue18] = useState(0)
  const [totalRewardContract24, setTotalRewardContractValue24] = useState(0)

  //Total de retiros (unstakings)
  const [totalWithdrawContract12N, setTotalWithdrawContractValue12N] = useState(0)
  const [totalWithdrawContract18N, setTotalWithdrawContractValue18N] = useState(0)
  const [totalWithdrawContract24N, setTotalWithdrawContractValue24N] = useState(0)
  const [totalWithdrawContract12, setTotalWithdrawContractValue12] = useState(0)
  const [totalWithdrawContract18, setTotalWithdrawContractValue18] = useState(0)
  const [totalWithdrawContract24, setTotalWithdrawContractValue24] = useState(0)

  //Total withdraw expirados ()
  const [totalWithdrawExpContract12N, setTotalWithdrawExpContractValue12N] = useState(0)
  const [totalWithdrawExpContract18N, setTotalWithdrawExpContractValue18N] = useState(0)
  const [totalWithdrawExpContract24N, setTotalWithdrawExpContractValue24N] = useState(0)
  const [totalWithdrawExpContract12, setTotalWithdrawExpContractValue12] = useState(0)
  const [totalWithdrawExpContract18, setTotalWithdrawExpContractValue18] = useState(0)
  const [totalWithdrawExpContract24, setTotalWithdrawExpContractValue24] = useState(0)

  

  //Total de stakings
  const [totalStakingContract12N, setTotalStakingContractValue12N] = useState(0);
  const [totalStakingContract18N, setTotalStakingContractValue18N] = useState(0);
  const [totalStakingContract24N, setTotalStakingContractValue24N] = useState(0);
  const [totalStakingContract12, setTotalStakingContractValue12] = useState(0);
  const [totalStakingContract18, setTotalStakingContractValue18] = useState(0);
  const [totalStakingContract24, setTotalStakingContractValue24] = useState(0);

  //Total staking expirados
  const [totalStakingActContractValue12, setTotalStakingActContractValue12] = useState(0);
  const [totalStakingActContractValue18, setTotalStakingActContractValue18] = useState(0);
  const [totalStakingActContractValue24, setTotalStakingActContractValue24] = useState(0);
  const [totalStakingActContractValue12N, setTotalStakingActContractValue12N] = useState(0);
  const [totalStakingActContractValue18N, setTotalStakingActContractValue18N] = useState(0);
  const [totalStakingActContractValue24N, setTotalStakingActContractValue24N] = useState(0);
  

  //Grafica staking over time highChart
  const [graphDate12, setGraphDate12] = useState({});
  const [graphDate18, setGraphDate18] = useState({});
  const [graphDate24, setGraphDate24] = useState({});

  const [graphAccumulateDate12, setGraphAccumulateDate12] = useState({});
  const [graphAccumulateDate18, setGraphAccumulateDate18] = useState({});
  const [graphAccumulateDate24, setGraphAccumulateDate24] = useState({});

  const [graphKeys12, setGraphKeys12] = useState([]);
  const [graphKeys18, setGraphKeys18] = useState([]);
  const [graphKeys24, setGraphKeys24] = useState([]);
  const [graphVals12, setGraphVals12] = useState([]);
  const [graphVals18, setGraphVals18] = useState([]);
  const [graphVals24, setGraphVals24] = useState([]);

  //Grafica staking over time ChartJS
  const [chartDates, setChartDates] = useState([]);
  const [chartKeys12, setChartKeys12] = useState([]);
  const [chartKeys18, setChartKeys18] = useState([]);
  const [chartKeys24, setChartKeys24] = useState([]);

  const labels = chartDates;

  const optionsGrafica = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const dataGrafica = {
    labels,
    datasets: [
      {
        label: "RED12",
        data: chartKeys12,
        backgroundColor: "#2196F3",
        borderColor: "#2196F3",
      },
      {
        label: "RED18",
        data: chartKeys18,
        backgroundColor: "#F44236",
        borderColor: "#F44236",
      },
      {
        label: "RED24",
        data: chartKeys24,
        backgroundColor: "#FFCA29",
        borderColor: "#FFCA29",
      },
    ],
  };

  const [totalEarned, setTotalEarnedValue] = useState(0)
  const [activeContractType, setActiveContractType] = useState("12");
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeAPR, setActiveAPR] = useState("12");
  const [canUnStakeAll, setCanUnStakeAll] = useState(true);


  const [stakingHistory, setStakingHistory] = useState([]);
  
  const [stakingLog, setStakingLog] = useState([]);
  
  const [value, setValue] = React.useState(0);
  const [isOwner, setIsOwner] = React.useState(false);

  const [tokenData2, setTokenData2] = useState(tokenData);

  const [ownerAddress12, setOwnerAddress12] = useState("");
  const [ownerAddress18, setOwnerAddress18] = useState("");
  const [ownerAddress24, setOwnerAddress24] = useState("");
  
  const [logedAddress, setLogedAddress] = useState("");

  /* modal stake */
  const [openStake, setOpenStake] = React.useState(false);
  const handleClickOpenStake = () => {
    setOpenStake(true);
  };
  const handleCloseStake = () => {
    setOpenStake(false);
  };

  /* modal claim */
  const [openClaim, setOpenClaim] = React.useState(false);
  const handleClickOpenClaim = () => {
    setOpenClaim(true);
  };
  const handleCloseClaim = () => {
    setOpenClaim(false);
  };

  /* modal unstake */
  const [openUnStake, setOpenUnStake] = React.useState(false);
  const handleClickOpenUnStake = () => {
    setOpenUnStake(true);
  };
  const handleCloseUnStake = () => {
    setOpenUnStake(false);
  };

  useEffect(() => {

    async function fetchData(){
        const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
        const name = await web3Token.methods.name().call();
        const symbol = await web3Token.methods.symbol().call();
        const totalSupply = await web3Token.methods.totalSupply().call();
        const decimals = await web3Token.methods.decimals().call();

        const accounts = await web3.eth.getAccounts();
        //setAccountAddress(accounts[0]);
        const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();

        setTokenData2(tokenData2 => [
            tokenData2[0],
            { ...tokenData2[1], value: name},
            { ...tokenData2[2], value: symbol},
            { ...tokenData2[3], value: applyDecimals(totalSupply, decimals)},
            { ...tokenData2[4], value: decimals},
            { ...tokenData2[5], value: applyDecimals(currentBalance, decimals)},
        ])
    }
    fetchData();
    CheckIsOwner();
    //if(isOwner){
      getTotalStakedContract();
      getTotalStakedContractAll();
      getAllowance();
      getStakingLog(); 
      fillTables();
    //}
}, [value]);

//updateValues();
  
  
  
  

function updateValues(){
  /*console.log("llamando");
  getStaking();
  getBalance();
  getReward();
  getTotalStakedContract();
  getTotalStakedContractAll();
  getAllowance();
  if(isOwner){
    getStakingLog();
  }*/
  
}

  /* TAB VARIABLES */
  
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    
    if(newValue === 0){
      setActiveContractType("12");
      setActiveIndex(0);
      setActiveAPR("12");
    }else if(newValue === 1){
      setActiveContractType("18");
      setActiveIndex(1);
      setActiveAPR("24");
    }else{
      setActiveContractType("24");
      setActiveIndex(2);
      setActiveAPR("36");
    }
    
  };
  /* END TAB VARIABLES */

  // request access to the user's account. This works regardless of the wallet you're using. 
  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  // call the smart contract, read the current greeting value
  async function getStaking() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      }
      
      try {
        const data = await contract.connect(walletAccount).getStaking();

        let stakingLen = data[0].length;
        //creacion de los objetos 
        let innerHistory = []
        var canUnStakeAll = true;
        for (let indexStaking = 0; indexStaking < stakingLen; indexStaking++) {
          let stakingObj = createData(
            indexStaking,
            data[0][indexStaking] / 1e18, 
            new Date(data[1][indexStaking] * 1000)+"", 
            data[2][indexStaking]?"true":"false", 
            );
          if(data[2][indexStaking]){
            canUnStakeAll = canUnStakeAll && (new Date(data[1][indexStaking] * 1000) < new Date())
          }
          innerHistory.push(stakingObj);
          
        }
        setCanUnStakeAll(canUnStakeAll);
        
        setStakingHistory(innerHistory);

      } catch (err) {
        console.log("Error: ", err)
      }
    }    
  }

  // call the smart contract, read the current greeting value
  async function getStakingLog() {
    let logObj = [];
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      }
      
      try {
        const data = await contract.connect(walletAccount).getStakingsLog();
        let stakingLen = data[0].length;
        
        //creacion de los objetos 
        var canUnStakeAll = true;
        for (let indexStaking = 0; indexStaking < stakingLen; indexStaking++) {
          let eType = "";
          if(parseInt(data[3][indexStaking]) === 1){
            eType = "Staking"
          }else if(parseInt(data[3][indexStaking]) === 2){
            eType = "Claim Rewards"
          }else{
            eType = "Withdraw"
          }
          let stakingLogObj = createLogData(
            indexStaking,
            data[0][indexStaking], 
            data[1][indexStaking] / 1e18, 
            new Date(data[2][indexStaking] * 1000)+"", 
            eType
          );
            logObj.push(stakingLogObj);
          
        }
        setStakingLog(logObj);
        //setTotalRewardContractValue12()
        //setTotalRewardContractValue18()
        //setTotalRewardContractValue24()
      } catch (err) {
        console.log("Error: ", err)
      }
    }
    //return logObj;
  }


  async function fillTables() {
    let logObj = [];
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract;
      let fecha,sfecha="",fechaPrev="";
      let objDateStaking12={},objDateStaking18={},objDateStaking24={};
      let objDateAccummulateStaking12={},objDateAccummulateStaking18={},objDateAccummulateStaking24={};
      let acummulate12=0,acummulate18=0,acummulate24=0;
      let hoy = new Date();
      
      try {
        //for 12
        let rewardSum12 = 0;
        let withdrawSum12 = 0;
        let withdrawCount12 = 0;
        let withdrawSumExp12 = 0;
        let withdrawCountExp12 = 0;
        let stakingActSum12 = 0;
        let stakingActCount12 = 0;
        let stakingSum12 = 0;
        let stakingCount12 = 0;
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);

        let data = await contract.connect(walletAccount).getStakingsLog();
        let stakingLen = data[0].length;        
        
        for (let indexStaking = 0; indexStaking < stakingLen; indexStaking++) {          
          if(parseInt(data[3][indexStaking]) === 1){
            //eType = "Staking" 
            stakingSum12 += (data[1][indexStaking] / 1e18);
            stakingCount12 += 1;

            fecha = new Date(data[2][indexStaking] * 1000);

            // datos de grfica
            sfecha  = formate_date(fecha);
            var sStaking = (data[1][indexStaking] / 1e18);
            if(sfecha in objDateStaking12){
              objDateStaking12[sfecha] = objDateStaking12[sfecha] + sStaking;
            }else{
              objDateStaking12[sfecha] =  sStaking;
            }

            if(!(sfecha in objDateStaking18)){
              objDateStaking18[sfecha] =  0;
            }

            if(!(sfecha in objDateStaking24)){
              objDateStaking24[sfecha] =  0;
            }

            //acumulate
            if(fechaPrev==""){
              fechaPrev = sfecha; 
            }

            if(fechaPrev != sfecha){
              objDateAccummulateStaking12[fechaPrev] = acummulate12;
              fechaPrev = sfecha;
            }
            acummulate12 += sStaking;

            //staking exp
            var fechaExp = new Date(data[2][indexStaking] * 1000);
            fechaExp.setDate(fechaExp.getDate() + 365);

            if(fechaExp < hoy){
              stakingActSum12 += (data[1][indexStaking] / 1e18);
              stakingActCount12 += 1;
            }

            
          }else if(parseInt(data[3][indexStaking]) === 2){
            //eType = "Claim Rewards"
            rewardSum12 += (data[1][indexStaking] / 1e18);
          }else{
            //eType = "Withdraw"
            withdrawSum12 += (data[1][indexStaking] / 1e18);
            withdrawCount12 += 1;
          }

          //acumulado final
          if(indexStaking+1 == stakingLen){
            objDateAccummulateStaking12[sfecha] = acummulate12;
          }
        }

        setTotalRewardContractValue12(rewardSum12);
        setTotalWithdrawContractValue12(withdrawSum12);
        setTotalWithdrawContractValue12N(withdrawCount12);        
        setTotalStakingContractValue12(stakingSum12);
        setTotalStakingContractValue12N(stakingCount12);
        setTotalWithdrawExpContractValue12(withdrawSumExp12);
        setTotalWithdrawExpContractValue12N(withdrawCountExp12);
        setTotalStakingActContractValue12(stakingActSum12);
        setTotalStakingActContractValue12N(stakingActCount12);

        //for 18
        let rewardSum18 = 0;
        let withdrawSum18 = 0;
        let withdrawCount18 = 0;
        let withdrawSumExp18 = 0;
        let withdrawCountExp18 = 0;
        let stakingSum18 = 0;
        let stakingCount18 = 0;
        let stakingActSum18 = 0;
        let stakingActCount18 = 0;

        sfecha="";
        fechaPrev="";
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);

        let data18 = await contract.connect(walletAccount).getStakingsLog();
        let stakingLen18 = data18[0].length;        

        for (let indexStaking = 0; indexStaking < stakingLen18; indexStaking++) {
          if(parseInt(data18[3][indexStaking]) === 1){
            //eType = "Staking" 
            stakingSum18 += (data18[1][indexStaking] / 1e18);
            stakingCount18 += 1;

            fecha = new Date(data18[2][indexStaking] * 1000);

            // datos de grfica
            sfecha  = formate_date(fecha);
            var sStaking = (data18[1][indexStaking] / 1e18);
            if(sfecha in objDateStaking18){
              objDateStaking18[sfecha] = objDateStaking18[sfecha] + sStaking;
            }else{
              objDateStaking18[sfecha] =  sStaking;
            }

            if(!(sfecha in objDateStaking12)){
              objDateStaking12[sfecha] =  0;
            }

            if(!(sfecha in objDateStaking24)){
              objDateStaking24[sfecha] =  0;
            }

            //acumulate
            if(fechaPrev==""){
              fechaPrev = sfecha; 
            }

            if(fechaPrev != sfecha){
              objDateAccummulateStaking18[fechaPrev] = acummulate18;
              fechaPrev = sfecha;
            }
            acummulate18 += sStaking;

            //staking exp
            var fechaExp = new Date(data18[2][indexStaking] * 1000);
            fechaExp.setDate(fechaExp.getDate() + 548);

            if(fechaExp < hoy){
              stakingActSum18 += (data18[1][indexStaking] / 1e18);
              stakingActCount18 += 1;
            }

          }else if(parseInt(data18[3][indexStaking]) === 2){
            //eType = "Claim Rewards"
            rewardSum18 += (data18[1][indexStaking] / 1e18);
          }else{
            //eType = "Withdraw"
            withdrawSum18 += (data18[1][indexStaking] / 1e18);
            withdrawCount18 += 1;
          }

          //acumulado final
          if(indexStaking+1 == stakingLen18){
            objDateAccummulateStaking18[sfecha] = acummulate18;
          }
        }
        setTotalRewardContractValue18(rewardSum18);
        setTotalWithdrawContractValue18(withdrawSum18);
        setTotalWithdrawContractValue18N(withdrawCount18);
        setTotalStakingContractValue18(stakingSum18);
        setTotalStakingContractValue18N(stakingCount18);
        setTotalWithdrawExpContractValue18(withdrawSumExp18);
        setTotalWithdrawExpContractValue18N(withdrawCountExp18);
        setTotalStakingActContractValue18(stakingActSum18);
        setTotalStakingActContractValue18N(stakingActCount18);

        //for 24
        let rewardSum24 = 0;
        let withdrawSum24 = 0;
        let withdrawCount24 = 0;
        let withdrawSumExp24 = 0;
        let withdrawCountExp24 = 0;
        let stakingSum24 = 0;
        let stakingCount24 = 0;
        let stakingActSum24 = 0;
        let stakingActCount24 = 0;

        sfecha="";
        fechaPrev="";
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);

        let data24 = await contract.connect(walletAccount).getStakingsLog();
        let stakingLen24 = data24[0].length;        
        
        for (let indexStaking = 0; indexStaking < stakingLen24; indexStaking++) {
          if(parseInt(data24[3][indexStaking]) === 1){
            //eType = "Staking"
            stakingSum24 += (data24[1][indexStaking] / 1e18);
            stakingCount24 += 1;

            fecha = new Date(data24[2][indexStaking] * 1000);

            // datos de grfica
            sfecha  = formate_date(fecha);
            var sStaking = (data24[1][indexStaking] / 1e18);
            if(sfecha in objDateStaking24){
              objDateStaking24[sfecha] = objDateStaking24[sfecha] + sStaking;
            }else{
              objDateStaking24[sfecha] =  sStaking;
            }

            if(!(sfecha in objDateStaking12)){
              objDateStaking12[sfecha] =  0;
            }

            if(!(sfecha in objDateStaking18)){
              objDateStaking18[sfecha] =  0;
            }

            //acumulate
            if(fechaPrev==""){
              fechaPrev = sfecha; 
            }

            if(fechaPrev != sfecha){
              objDateAccummulateStaking24[fechaPrev] = acummulate24;
              fechaPrev = sfecha;
            }
            acummulate24 += sStaking;

            //staking exp
            var fechaExp = new Date(data24[2][indexStaking] * 1000);
            fechaExp.setDate(fechaExp.getDate() + 730);
            if(fechaExp < hoy){
              stakingActSum24 += (data24[1][indexStaking] / 1e18);
              stakingActCount24 += 1;
            }
            
          }else if(parseInt(data24[3][indexStaking]) === 2){
            //eType = "Claim Rewards"
            rewardSum24 += (data24[1][indexStaking] / 1e18);
          }else{
            //eType = "Withdraw"
            withdrawSum24 += (data24[1][indexStaking] / 1e18);
            withdrawCount24 += 1;

            if(fecha < hoy){
              withdrawSumExp24 += (data24[1][indexStaking] / 1e18);
              withdrawCountExp24 += 1;
            }
          }

          //acumulado final
          if(indexStaking+1 == stakingLen24){
            objDateAccummulateStaking24[sfecha] = acummulate24;
          }
        }
        setTotalRewardContractValue24(rewardSum24);
        setTotalWithdrawContractValue24(withdrawSum24);
        setTotalWithdrawContractValue24N(withdrawCount24);
        setTotalWithdrawExpContractValue24(withdrawSumExp24);
        setTotalWithdrawExpContractValue24N(withdrawCountExp24);
        setTotalStakingContractValue24(stakingSum24);
        setTotalStakingContractValue24N(stakingCount24);
        setTotalStakingActContractValue24(stakingActSum24);
        setTotalStakingActContractValue24N(stakingActCount24);

        //Asignacion de relacion fecha-staking
        console.log("staking por dia");
        setGraphDate12(objDateStaking12);
        setGraphDate18(objDateStaking18);
        setGraphDate24(objDateStaking24);

        console.log(objDateStaking12);
        console.log(objDateStaking18);
        console.log(objDateStaking24);

        console.log("staking acumulados");
        setGraphAccumulateDate12(objDateAccummulateStaking12);
        setGraphAccumulateDate18(objDateAccummulateStaking18);
        setGraphAccumulateDate24(objDateAccummulateStaking24);
        
        //HIGHCHARTS
        let keysList = [];
        for (const property in objDateAccummulateStaking12) {
          console.log("entro****");
          keysList.push([property,objDateAccummulateStaking12[property]]);
        }
        console.log(keysList)
        setGraphKeys12(keysList);
        

        let keysList18 = [];
        for (const property in objDateAccummulateStaking18) {
          keysList18.push([property,objDateAccummulateStaking18[property]]);
        }
        console.log(keysList18)
        setGraphKeys18(keysList18);
        

        let keysList24 = [];
        
        for (const property in objDateAccummulateStaking24) {
          keysList24.push([property,objDateAccummulateStaking24[property]]);
          
        }
        console.log(keysList24)
        setGraphKeys24(keysList24);


        //CHARTJS
        let keys2List = [];
        let chartFechasList = [];
        for (const property in objDateAccummulateStaking12) {
          keys2List.push(objDateAccummulateStaking12[property]);
          chartFechasList.push(property);
        }
        console.log(chartFechasList);
        setChartDates(chartFechasList);
        console.log(keys2List);
        setChartKeys12(keys2List);
        
        
        let keys2List18 = [];
        for (const property in objDateAccummulateStaking18) {
          keys2List18.push(objDateAccummulateStaking18[property]);
        }
        console.log(keys2List18);
        setChartKeys18(keys2List18);
        

        let keys2List24 = [];
        for (const property in objDateAccummulateStaking24) {
          keys2List24.push(objDateAccummulateStaking24[property]);
        }
        console.log(keys2List24)
        setChartKeys24(keys2List24);

        //const [chartDates, setChartDates] = useState([]);
        //const [ChartKeys12, setChartKeys12] = useState([]);
        
      } catch (err) {
        console.log("Error: ", err)
      }
    }
    //return logObj;
  }

  function formate_date(fecha){
    let year = fecha.getFullYear();
    let month = fecha.getMonth()+1;
    let day = fecha.getDate();

    return (day+"/"+month+"/"+year);
  }
  

  // call the smart contract, send an update
  async function setStaking() {
    handleCloseStake();
    if (!staking) return
    if (typeof window.ethereum !== 'undefined') {
      
      let stakingAmount = ethers.utils.parseEther(staking)
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer)
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer)
      }

      const transaction = await contract.stake(stakingAmount, {gasLimit: 2000000});
      await transaction.wait();
      setStakingValue("");
      updateValues();
    }
  }

  // call the smart contract, send an update
  async function claim() {
    handleCloseClaim();
    if (typeof window.ethereum !== 'undefined') {
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      //const contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
      //const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
      //await tokenR.approve(stakingAddress, staking)

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer);
      }

      const transaction = await contract.claimReward({gasLimit: 2000000});
      await transaction.wait()
      updateValues();
    }
  }

  async function getReward(){
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      }
      try {
        const earned = await contract.earned(walletAccount)
        //const paid = await contract.s_rewards(walletAccount) 
        //let rewards = (earned - paid) / 1e18
        let rewards = earned / 1e18
        setRewardValue(rewards);
        //console.log('data reward: ', rewards)
      } catch (err) {
        console.log("Error: ", err)
      }
    } 
  }

  async function getAllowance() {
    if (typeof window.ethereum !== 'undefined') {
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()

      let contract;
      let data;
      //try{
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        data = await tokenR.connect(signer).allowance(signer.getAddress(), stakingAddress)
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        data = await tokenR.allowance(signer.getAddress(), stakingAddress18);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        data = await tokenR.allowance(signer.getAddress(), stakingAddress24);
      }

      setAllowanceValue(data / 1e18);

      
    }
  }

  async function CheckIsOwner() {
    if (typeof window.ethereum !== 'undefined') {
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      //const address = signer.getAddress();
      const signerAddress = await signer.getAddress();
      setLogedAddress(signerAddress);

      let contract;
      let data;
      //try{
      if(activeContractType==="12"){
        console.log("revisa staking12");
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
        data = await contract.owner();
        console.log("owner 12");
        console.log(data);
        setOwnerAddress12(data);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer)
        data = await contract.owner();
        console.log("owner 12");
        console.log(data);
        setOwnerAddress18(data);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer)
        data = await contract.owner();
        console.log("owner 12");
        console.log(data);
        setOwnerAddress24(data);
      }
      //const signerAddress = await signer.getAddress();
      //setLogedAddress(signerAddress);
      setIsOwner( signerAddress === data);
    }
  }

   // call the smart contract, send an update
   async function setAllowance() {
    if (!staking) return
    if (typeof window.ethereum !== 'undefined') {
      let stakingAmount = ethers.utils.parseEther(staking)
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        await tokenR.connect(signer).approve(stakingAddress, stakingAmount,{gasLimit: 2000000})
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        await tokenR.connect(signer).approve(stakingAddress18, stakingAmount, {gasLimit: 2000000})
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        await tokenR.connect(signer).approve(stakingAddress24, stakingAmount, {gasLimit: 2000000})
      }

      updateValues();
    }
  }



  async function getBalance(){
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum)

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      }

      try {
        const data = await contract.connect(walletAccount).getBalance();
        let balance = data / 1e18
        setBalanceValue(balance);
        //console.log('data balance: ', balance)
      } catch (err) {
        console.log("Error: ", err)
      }
    } 
  }

    // call the smart contract, send an update
    async function unStake(id) {
      handleCloseUnStake();
      //console.log(id);
      if (typeof window.ethereum !== 'undefined') {
        //boton true 
        await requestAccount()
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        //const contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)

        let contract;
        if(activeContractType==="12"){
          contract = new ethers.Contract(stakingAddress, Staking.abi, signer);
        }else if(activeContractType==="18"){
          contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer);
        }else{
          contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer);
        }
        
        const transaction = await contract.withdraw(id)
        await transaction.wait()
        updateValues();
      }
    }

    
    async function getTotalStakedContract(){
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let contract;
        if(activeContractType==="12"){
          contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
        }else if(activeContractType==="18"){
          contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
        }else{
          contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
        }
        try {
          const data = await contract.s_totalSupply();
          let balance = data / 1e18
          setTotalStakedContractValue(balance);
          //console.log('data balance: ', balance)
        } catch (err) {
          console.log("Error: ", err)
        }
      } 
    }

    async function getTotalStakedContractAll(){
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let contract;
        let data;
        let balance;
        let funding;
          
        
        try {
          contract = new ethers.Contract(stakingAddress, Staking.abi, provider);        
          data = await contract.s_totalSupply();
          balance = data / 1e18;
          setTotalStakedContractValue12(balance);

          funding = await contract.s_totalFunding() / 1e18
          setFondeo12(funding);

          contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
          data = await contract.s_totalSupply();
          balance = data / 1e18;
          setTotalStakedContractValue18(balance);

          funding = await contract.s_totalFunding() / 1e18
          setFondeo18(funding);

          contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
          data = await contract.s_totalSupply();
          balance = data / 1e18;
          setTotalStakedContractValue24(balance);

          funding = await contract.s_totalFunding() / 1e18
          setFondeo24(funding);
          //console.log('data balance: ', balance)
        } catch (err) {
          console.log("Error: ", err)
        }
      } 
    }

    async function getTotalEarned(){
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        //const contract = new ethers.Contract(stakingAddress, Staking.abi, provider)
        let contract;
        if(activeContractType==="12"){
          contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
        }else if(activeContractType==="18"){
          contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
        }else{
          contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
        }
        try {
          const data = await contract.earned(walletAccount);
          let balance = data / 1e18
          setTotalEarnedValue(balance);
          //console.log('data balance: ', balance)
        } catch (err) {
          console.log("Error: ", err)
        }
      } 
    }

    /*console.log(graphKeys12);
        console.log(graphKeys18);
        console.log(graphKeys24);*/

    /*const options = {
      title: {
        text: 'Detalle de Staking Over Time'
      },
      chart: {height: 215},
      xAxis: {
        tickInterval: 1,
        labels: {
            enabled: true,
            formatter: function() { return graphKeys12[this.value][0];},
        }
    },
      series: [{name: 'RED12', data: graphKeys12},
               {name: 'RED18', data: graphKeys18 },
               {name: 'RED24', data: graphKeys24 }]
    }*/

  return (
    <>
      <CardInfo walletAccount={walletAccount} tokenData={tokenData2} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/>
      <Box sx={{ width: '100%', marginBottom:'5' }}>
      <h5>Please select a period of Staking:</h5>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="RED12" {...a11yProps(0)} />
          <Tab label="RED18" {...a11yProps(1)} />
          <Tab label="RED24" {...a11yProps(2)} />
        </Tabs>
      </Box>
      {/** tabs */}
      <TabPanel value={value} index={0}>
        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
        
        {isOwner ?
          <Grid item xs={12} >
              <CSVLink 
              data={stakingLog} 
              filename={"staking12.csv"}
              style={{textDecoration: 'none'}}
              >
                  <Button variant="contained"   style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                    Export
                </Button>
              </CSVLink>
              
          </Grid> :
          ""
        }
          
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} ><b>12 Month Staking Contract for RED</b><br/>
              APR: 12%</p>
              <p style={{textAlign: "left"}}><b>Address del contrato:</b> {stakingAddress}</p>
              <p style={{textAlign: "left"}}><b>Address del owner:</b> {ownerAddress12}</p>
              <p style={{textAlign: "left"}}><b>Address del conectado:</b> {logedAddress}</p>
              </Grid>

              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
          
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} >
            <TableContainer component={Paper} style={{ maxHeight: 850 }}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">ID</TableCell>
                    <TableCell align="right">Address</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Event</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {stakingLog.map((row) => (

                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">{row.Address}</TableCell>
                      <TableCell align="right">{moneyFormat(row.Amount)}</TableCell>
                      <TableCell align="right">{row.Date}</TableCell>
                      <TableCell align="right">{row.Event}</TableCell>
                    </TableRow>
                  ))}
                  
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={12} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >Total Staked in contract {moneyFormat(totalStakedContract)} RED<br/></p>
              </Grid>
          </Grid>
        </Grid>
      </TabPanel>





      <TabPanel value={value} index={1}>
      <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
            {isOwner ?
              <Grid item xs={12} >
                <CSVLink 
                data={stakingLog} 
                filename={"staking18.csv"}
                style={{textDecoration: 'none'}}
                >
                  <Button variant="contained"   style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                      Export
                  </Button>
                </CSVLink>
              </Grid> :
                ""
            }
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} ><b>18 Month Staking Contract for RED</b><br/>
              APR: 24%</p>
              <p style={{textAlign: "left"}}><b>Address del contrato:</b> {stakingAddress18}</p>
              <p style={{textAlign: "left"}}><b>Address del owner:</b> {ownerAddress18}</p>
              <p style={{textAlign: "left"}}><b>Address del conectado:</b> {logedAddress}</p>
              </Grid>

              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} >
            <TableContainer component={Paper} style={{ maxHeight: 850 }}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">ID</TableCell>
                    <TableCell align="right">Address</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Event</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {stakingLog.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">{row.Address}</TableCell>
                      <TableCell align="right">{moneyFormat(row.Amount)}</TableCell>
                      <TableCell align="right">{row.Date}</TableCell>
                      <TableCell align="right">{row.Event}</TableCell>
                    </TableRow>
                  ))}
                  
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>  
        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >Total Staked in contract {moneyFormat(totalStakedContract)} RED<br/></p>
              </Grid>
              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
      </TabPanel>


      <TabPanel value={value} index={2}>
      <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              {isOwner ?
              <Grid item xs={12} >
                  <CSVLink 
                  data={stakingLog} 
                  filename={"staking24.csv"}
                  style={{textDecoration: 'none'}}
                  >
                    <Button variant="contained"   style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                        Export
                    </Button>
                  </CSVLink>
              </Grid> :
                ""
              }
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} ><b>24 Month Staking Contract for RED</b><br/>
              APR: 36%</p>
              <p style={{textAlign: "left"}}><b>Address del contrato:</b> {stakingAddress24}</p>
              <p style={{textAlign: "left"}}><b>Address del owner:</b> {ownerAddress24}</p>
              <p style={{textAlign: "left"}}><b>Address del conectado:</b> {logedAddress}</p>
              </Grid>

              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
          
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} >
            <TableContainer component={Paper} style={{ maxHeight: 850 }}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">ID</TableCell>
                    <TableCell align="right">Address</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Event</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {stakingLog.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">{row.Address}</TableCell>
                      <TableCell align="right">{moneyFormat(row.Amount)}</TableCell>
                      <TableCell align="right">{row.Date}</TableCell>
                      <TableCell align="right">{row.Event}</TableCell>
                    </TableRow>
                  ))}
                  
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >Total Staked in contract {moneyFormat(totalStakedContract)} RED<br/></p>
              </Grid>
              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
      </TabPanel>

      {/** end tabs */}
      
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Total de RED en Staking:
              </Typography>
              
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">RED</TableCell>
                      <TableCell align="right">USDT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED12</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakedContract12)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakedContract12 * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED18</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakedContract18)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakedContract18 * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED24</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakedContract24)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakedContract24 * 0.1)}</TableCell>
                      </TableRow>
                    
                    
                  </TableBody>
                </Table>
              </TableContainer>
              
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom> Gráfica </Typography>
              {/*<HighchartsReact
                highcharts={Highcharts}
                options={options}
            />*/}
              <Line options={optionsGrafica} data={dataGrafica} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{marginTop:3}}>
        <Grid item xs={6}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Total de RED Pagado (Rewards):
              </Typography>
              
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">RED</TableCell>
                      <TableCell align="right">USDT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED12</TableCell>
                        <TableCell align="right">{moneyFormat(totalRewardContract12)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalRewardContract12 * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED18</TableCell>
                        <TableCell align="right">{moneyFormat(totalRewardContract18)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalRewardContract18 * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED24</TableCell>
                        <TableCell align="right">{moneyFormat(totalRewardContract24)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalRewardContract24 * 0.1)}</TableCell>
                      </TableRow>
                    
                    
                  </TableBody>
                </Table>
              </TableContainer>
              
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} >
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Total de Stakings Activos:
              </Typography>
              
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">Qty</TableCell>
                      <TableCell align="right">RED</TableCell>
                      <TableCell align="right">USDT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED12</TableCell>
                        <TableCell align="right">{totalStakingContract12N - totalWithdrawContract12N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakingContract12 - totalWithdrawContract12)}</TableCell>
                        <TableCell align="right">{moneyFormat((totalStakingContract12 - totalWithdrawContract12) * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED18</TableCell>
                        <TableCell align="right">{totalStakingContract18N - totalWithdrawContract18N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakingContract18 - totalWithdrawContract18)}</TableCell>
                        <TableCell align="right">{moneyFormat((totalStakingContract18 - totalWithdrawContract18) * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED24</TableCell>
                        <TableCell align="right">{totalStakingContract24N - totalWithdrawContract24N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakingContract24 - totalWithdrawContract24)}</TableCell>
                        <TableCell align="right">{moneyFormat((totalStakingContract24 - totalWithdrawContract24) * 0.1)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{marginTop:3}}>
        <Grid item xs={6}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Total de Stakings Expirados:
              </Typography>
              
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">Qty</TableCell>
                      <TableCell align="right">RED</TableCell>
                      <TableCell align="right">USDT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED12</TableCell>
                        <TableCell align="right">{totalStakingActContractValue12N - totalWithdrawContract12N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakingActContractValue12 - totalWithdrawContract12)}</TableCell>
                        <TableCell align="right">{moneyFormat((totalStakingActContractValue12 - totalWithdrawContract12) * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED18</TableCell>
                        <TableCell align="right">{totalStakingActContractValue18N - totalWithdrawContract18N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakingActContractValue18 - totalWithdrawContract18)}</TableCell>
                        <TableCell align="right">{moneyFormat((totalStakingActContractValue18 - totalWithdrawContract18) * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED24</TableCell>
                        <TableCell align="right">{totalStakingActContractValue24N - totalWithdrawContract24N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalStakingActContractValue24 - totalWithdrawContract24)}</TableCell>
                        <TableCell align="right">{moneyFormat((totalStakingActContractValue24 - totalWithdrawContract24) * 0.1)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} >
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Total de Stakings Retiros (Unstakings):
              </Typography>
              
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">Qty</TableCell>
                      <TableCell align="right">RED</TableCell>
                      <TableCell align="right">USDT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED12</TableCell>
                        <TableCell align="right">{totalWithdrawContract12N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalWithdrawContract12)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalWithdrawContract12 * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED18</TableCell>
                        <TableCell align="right">{totalWithdrawContract18N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalWithdrawContract18)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalWithdrawContract18 * 0.1)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right">RED24</TableCell>
                        <TableCell align="right">{totalWithdrawContract24N}</TableCell>
                        <TableCell align="right">{moneyFormat(totalWithdrawContract24)}</TableCell>
                        <TableCell align="right">{moneyFormat(totalWithdrawContract24 * 0.1)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{marginTop:3}}>
        <Grid item xs={6}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14, fontWeight:'bold' }} color="text.secondary" gutterBottom >
                Estadísticas de Fondeo:
              </Typography>
              
              <TableContainer component={Paper} sx={{marginBottom:3}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Red12:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Fondeo: {moneyFormat(fondeo12)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Red en Staking: {moneyFormat(totalStakedContract12)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Red Pagado (Rewards): {moneyFormat(totalRewardContract12)}</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Balance de fondeo: {moneyFormat(fondeo12-totalRewardContract12)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} sx={{marginBottom:3}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Red18:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Fondeo: {moneyFormat(fondeo18)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Red en Staking: {moneyFormat(totalStakedContract18)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Red Pagado (Rewards): {moneyFormat(totalRewardContract18)}</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Balance de fondeo: {moneyFormat(fondeo18 - totalRewardContract18)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Red24:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Fondeo: {moneyFormat(fondeo24)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Red en Staking: {moneyFormat(totalStakedContract24)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Red Pagado (Rewards): {moneyFormat(totalRewardContract24)}</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Balance de fondeo: {moneyFormat(fondeo24 - totalRewardContract24)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} >
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
            <Typography sx={{ fontSize: 14, fontWeight:'bold' }} color="text.secondary" gutterBottom>
                Estimación de Recompensas:
              </Typography>
              
              <TableContainer component={Paper} sx={{marginBottom:3}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Red12:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Próxima semana: {moneyFormat(totalStakedContract12 *.01 / 30.4 * 7)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Próximo mes: {moneyFormat(totalStakedContract12 * .01)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Próximos 12 meses: {moneyFormat(totalStakedContract12 * .01 * 12)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} sx={{marginBottom:3}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Red18:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Próxima semana: {moneyFormat(totalStakedContract18 *.02 / 30.4 * 7)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Próximo mes: {moneyFormat(totalStakedContract18 * .02)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Próximos 18 meses: {moneyFormat(totalStakedContract18 * .02 * 18)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Red24:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Próxima semana: {moneyFormat(totalStakedContract24 * .03 / 30.4 * 7)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Próximo mes: {moneyFormat(totalStakedContract24 * .03)}</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" >Próximos 24 meses: {moneyFormat(totalStakedContract24 * .03 * 24)}</TableCell>
                      </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
    </Box>
      
    </>
  )
}
export default Dashboard
