import React, {useState, useEffect} from 'react'
import {Typography } from '@mui/material';


import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


//const redMoneyToken = require("../../artifacts/contracts/RedMoney.sol/RedMoney.json");
import RedMoney from '../../artifacts/contracts/RedMoney.sol/RedMoney.json';
import Staking from '../../artifacts/contracts/Staking.sol/Staking.json';
import Staking18 from '../../artifacts/contracts/Staking18.sol/Staking18.json'; 
import Staking24 from '../../artifacts/contracts/Staking24.sol/Staking24.json';
import { ethers } from 'ethers'


const ERC20Token = require("../ERC20/ERC20Token");
const { web3, applyDecimals } = require("../../utils/ethereumAPI");

const Configuracion = ({walletAccount, tokenData, tokenAddress, stakingAddress, stakingAddress18, stakingAddress24}) => {

  const [tokenData2, setTokenData2] = useState(tokenData);
  const [totalSupply2, setTotalSupply2] = useState(0);
  const [cap, setCap] = useState(0);

  const [cantidad, setCantidad] = useState("");
  const [newCap, setNewCap] = useState("");
  const [fondeoStaking12, setFondeoStaking12] = useState("");
  const [fondeoStaking18, setFondeoStaking18] = useState("");
  const [fondeoStaking24, setFondeoStaking24] = useState("");
  const [stakingActive, setStakingActive] = useState(0);

  web3.givenProvider = web3.currentProvider;
  web3.eth.givenProvider = web3.currentProvider;
  web3.eth.accounts.givenProvider = web3.currentProvider;

  const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);

  const updateCantidad = (value) => {
    setCantidad(value); 
  };

  const updateNewCap = (value) => {
    setNewCap(value); 
  };

  const updateFondeoStaking12 = (value) => {
    setFondeoStaking12(value); 
  };

  const updateFondeoStaking18 = (value) => {
    setFondeoStaking18(value); 
  };

  const updateFondeoStaking24 = (value) => {
    setFondeoStaking24(value); 
  };


  useEffect(() => {
    async function fetchData(){
        const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
        //const redMoneyToken = new web3.eth.Contract(redMoneyToken.abi, tokenAddress);
        const name = await web3Token.methods.name().call();
        const symbol = await web3Token.methods.symbol().call();
        const totalSupply = await web3Token.methods.totalSupply().call();
        const decimals = await web3Token.methods.decimals().call();

        const accounts = await web3.eth.getAccounts();
        //setAccountAddress(accounts[0]);
        const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();
        
        setTotalSupply2(totalSupply / 1e18);
        setTokenData2(tokenData2 => [
            tokenData2[0],
            { ...tokenData2[1], value: name},
            { ...tokenData2[2], value: symbol},
            { ...tokenData2[3], value: applyDecimals(totalSupply, decimals)},
            { ...tokenData2[4], value: decimals},
            { ...tokenData2[5], value: applyDecimals(currentBalance, decimals)},
        ])
    }
    fetchData();
    getCap();
}, [tokenAddress]);
        

  const currentBalance = tokenData2.find(x => x.name === "Currentbalance").value;

  //const [state, setState] = useState(false);

  const moneyFormat = (value, money) => {
    let options = money ? { style: 'currency', currency: 'USD'} : {minimumFractionDigits: 2};
    return new Intl.NumberFormat('en-US', options).format(value);
  }

  async function getCap() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let token = new ethers.Contract(tokenAddress, RedMoney.abi, provider);
        
      
      try {
        const data = await token.connect(walletAccount).cap();
        setCap(data / 1e18);

      } catch (err) {
        console.log("Error en cap: ", err)
      }
    }    
  }

  async function setMint() {
    if (typeof window.ethereum !== 'undefined') {

      let mintAmount = ethers.utils.parseEther(cantidad)
      
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let token = new ethers.Contract(tokenAddress, RedMoney.abi, provider);

      console.log(walletAccount);
      console.log(mintAmount);
      const signer = provider.getSigner()

      try {
        // like cap
        //const data = await token.connect(walletAccount).mint(signer.getAddress(),mintAmount,{gasLimit: 2000000});
        let data = await token.connect(signer).mint(walletAccount, mintAmount,{gasLimit: 2000000});
        console.log(data);

        //like stake
        /*let token = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        const transaction = await token.mint(walletAccount,mintAmount, {gasLimit: 2000000});
        await transaction.wait();*/

      
      } catch (err) {
        console.log("Error en mint: ", err)
      }
    }
  }

  async function setNewCapF() {
    if (typeof window.ethereum !== 'undefined') {

      let newCapAmount = ethers.utils.parseEther(newCap)
      
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let token = new ethers.Contract(tokenAddress, RedMoney.abi, provider);

      console.log(walletAccount);
      
      const signer = provider.getSigner()

      try {
        // like cap
        //const data = await token.connect(walletAccount).mint(signer.getAddress(),mintAmount,{gasLimit: 2000000});
        let data = await token.connect(signer).setCap(newCapAmount,{gasLimit: 2000000});
        console.log(data);

        //like stake
        /*let token = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        const transaction = await token.mint(walletAccount,mintAmount, {gasLimit: 2000000});
        await transaction.wait();*/

      
      } catch (err) {
        console.log("Error en newCap: ", err)
      }
    }
  }

  async function fondearStaking() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let contract;
    let fundAmount;
    let toAddress;
    console.log(stakingAddress);
    console.log(stakingAddress18);
    console.log(stakingAddress24);

    if(stakingActive === "12"){
      console.log("fondeo a staking12 "+fondeoStaking12);
      contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      fundAmount = ethers.utils.parseEther(fondeoStaking12);
      toAddress = stakingAddress;
    }else if(stakingActive === "18"){
      console.log("fondeo a staking18 "+fondeoStaking18);
      contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      fundAmount = ethers.utils.parseEther(fondeoStaking18);
      toAddress = stakingAddress18;
    }else if(stakingActive === "24"){
      console.log("fondeo a staking24 "+fondeoStaking24);
      contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      fundAmount = ethers.utils.parseEther(fondeoStaking24);
      toAddress = stakingAddress24;
    }else{
      console.log("No hay un staking activo para fondear");
    }

    try {
      console.log(signer);
      console.log(signer.getAddress());

      //transfer
      //const transfer = await contract.connect(signer).fundContract(fundAmount)
      const accounts = await web3.eth.getAccounts();
      const gasPrice = await web3.eth.getGasPrice();

      const amountToSend = fundAmount;
      await web3Token.methods.transfer(toAddress, amountToSend)
                          .send({ from: accounts[0], gasPrice: gasPrice});

      //found
      const fund = await contract.connect(signer).fundContract(fundAmount)
      
      //let rewards = earned / 1e18
      //setRewardValue(rewards);
      console.log(fund)
    } catch (err) {
      console.log("Error: ", err)
    }

    
  }

  return (
    <Grid container spacing={2}>
        
        <Grid item xs={12}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h4" component="div" margin={3}>
                Token Administration
              </Typography>

              <Grid container spacing={1} margin={1}>
                <Grid item xs={2}>
                  <Typography component="p" margin={1}> Total in Owner Account:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField id="outlined-basic" label={moneyFormat(currentBalance)} variant="outlined" size="large"/> <Typography component="span" margin={1}>  RED</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} margin={1}>
                <Grid item xs={2}>
                  <Typography component="p" margin={1}> Total Supply:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField id="outlined-basic" label={moneyFormat(totalSupply2)} variant="outlined" size="large"/> <Typography component="span" margin={1}>  RED</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} margin={1}>
                <Grid item xs={4}>
                  <Typography component="p" margin={1}> Current Token Cap:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField id="outlined-basic" label={moneyFormat(cap)} variant="outlined" size="small"/> <Button variant="outlined">Pause / Unpause</Button>
                </Grid>
              </Grid>

              <Grid container spacing={2} margin={1}>
                <Grid item xs={4}>
                  <Typography component="p" margin={1}> Mint New Tokens:</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Cantidad"
                    type="number"
                    fullWidth
                    variant="standard"
                    InputProps={{max: currentBalance, min: 0}}
                    onChange={(e) => {
                      var value = e.target.value;
                      updateCantidad(value);
                    }}
                    value={cantidad}
                  />
                  {/*<TextField value={mintValue} onChange={onChangeMintValue} id="outlined-basic" label="" variant="outlined" size="small"/>
                  <Input 
                    //onChange={handleChange}
                    onBlur={handleChange}
                  id="my-input" aria-describedby="my-helper-text" />*/}
                  
                  <Button variant="outlined" onClick={setMint}>Mint token</Button>
                  
                </Grid>
              </Grid>

              <Grid container spacing={2} margin={1}>
                <Grid item xs={4}>
                  <Typography component="p" margin={1}> Set New Token Cap:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField id="outlined-basic" 
                  onChange={(e) => {
                    var value = e.target.value;
                    updateNewCap(value);
                  }}
                  value={newCap}
                  label="" variant="outlined" size="small"/> <Button variant="outlined" onClick={setNewCapF} >New Cap</Button>
                </Grid>
              </Grid>

              
              <Grid container spacing={2} margin={1}>
                <Grid item xs={4}>
                  <Typography component="p" margin={1}> Fondear Staking12:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField id="outlined-basic"
                  onChange={(e) => {
                    var value = e.target.value;
                    updateFondeoStaking12(value);
                    setStakingActive("12")
                  }}
                  value={fondeoStaking12}
                  label="" variant="outlined" size="small"/> <Button variant="outlined" onClick={fondearStaking}>Fondeo</Button>
                </Grid>
              </Grid>

              <Grid container spacing={2} margin={1}>
                <Grid item xs={4}>
                  <Typography component="p" margin={1}> Fondear Staking18:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField id="outlined-basic" onChange={(e) => {
                    var value = e.target.value;
                    updateFondeoStaking18(value);
                    setStakingActive("18")
                  }}
                  value={fondeoStaking18}
                  label="" variant="outlined" size="small"/> <Button variant="outlined" onClick={fondearStaking}>Fondeo</Button>
                </Grid>
              </Grid>

              <Grid container spacing={2} margin={1}>
                <Grid item xs={4}>
                  <Typography component="p" margin={1}> Fondear Staking24:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField id="outlined-basic" 
                  onChange={(e) => {
                    var value = e.target.value;
                    updateFondeoStaking24(value);
                    setStakingActive("24")
                  }}
                  value={fondeoStaking24}
                  label="" variant="outlined" size="small"/> <Button variant="outlined" onClick={fondearStaking}>Fondeo</Button>
                </Grid>
              </Grid>

              {/*<Grid container spacing={2} margin={1}>
                <Grid item xs={4}>
                  <Typography component="p" margin={1}> Exportar movimientos</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button variant="outlined">Exportar</Button>
                </Grid>
              </Grid>*/}
              
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  )
}

export default Configuracion