import * as React from 'react';
import { Box, Container, Grid } from '@mui/material';

import red_logo_2 from '../assets/img/logos/LOGO-RED-MONEY.png';
import phoneMockup from '../assets/img/phone-mockup.png';
import '../assets/css/style.css';

export default class AppLogin extends React.Component{

    render(){
      return (
        <Box className="home-landing" sx={{py:4}}>
          <Container>
            <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{minHeight: '100vh'}}>
              <Grid item xs={12} sm={8} lg={6}>
                <Box className="logo-landing">
                  <img style={{maxWidth:'100%', display: 'block'}} src={red_logo_2} alt=""/>
                </Box>
                <Box className="caption-landing">
                  <h2>Utiliza <b>Red Money</b> <br/>!ahora mismo!</h2>
                  <p>
                    Conecta tu billetera como <b>Administrador</b> <br/>ingresando dando clic aquí
                  </p>
                </Box>
                <Box>
                  {this.props.children}
                </Box>
                {/*<Box className="instrucciones-landing" sx={{pt:2}}>
                  <h3>Instrucciones</h3>
                  <Button variant="contained" className="btn-instruccion" size="large" target="_blank"
                  href={'https://youtu.be/ne1vqmK7Pg4'}>Para Celular</Button>
                  <Button variant="contained" className="btn-instruccion" size="large" target="_blank"
                  href={'https://youtu.be/eLwT5YrGCHs'}>Para PC</Button>
                </Box>*/}
              </Grid>
              <Grid item xs={12} sm={8} lg={6}>
                <Box className="phone-landing">
                  <img style={{maxWidth:'100%', display: 'block'}} src={phoneMockup} alt=""/>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      );

    }
}